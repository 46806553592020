import { DuplicateAccountDTO, DuplicateAccountManagementDTO } from "../types/Accounts";
import { SettingsDTO } from "../types/Admin";
import {
  ConsentFormDescriptionDTO,
  TppWaiverDescriptionDTO,
  WaiverDescriptionDTO,
  WaiverQuery,
} from "../types/Waivers";
import { ClubUserSettingsDTO } from "../types/ClubUserSettings";
import { FileUploadDTO, ProspectUploadDTO, RequiredDocument, TutorialUploadDTO } from "../types/Documents";
import { HelpDescription, HelpResourceDTO } from "../types/Help";
import { InviteDTO } from "../types/Invitation";
import { EmailAddressDTO, PhoneNumberDTO, ProspectGridFilters, ProspectGridPage } from "../types/Prospect";
import { TaskDTO, TaskAssignmentDTO } from "../types/Tasks";
import { TrainerDTO, TrainerGridFilters, TrainerGridPage, TrainerProfileDTO } from "../types/Trainers";
import { UserDetails } from "../types/User";
import AuthClient from "./AuthClient";
import {
  ProspectDTO,
  ProspectProfessionalTeamDTO,
  ProspectProfileDTO,
  ProspectTravelDateDTO,
  SaveProspectProfileDTO,
} from "../types/ProspectProfile";
import {
  ConsentFormGuardianSignersDTO,
  ConsentFormSignerDTO,
  ProspectConsentFormConfig,
  ProspectConsentFormDTO,
} from "../types/ProspectConsentForm";

/* User Details */
export const getUserInfo = async (): Promise<UserDetails> => (await AuthClient.get(`/user/info`)).data.entity;

/* Prospect Grid */
export const getProspects = async (
  filters: ProspectGridFilters,
  page: number,
  size: number,
): Promise<ProspectGridPage> =>
  (await AuthClient.post(`/prospectGrid/filterProspects?page=${page}&size=${size}`, filters)).data.entity;

export const downloadAllDocuments = async (prospectId: number): Promise<any> =>
  await AuthClient.get(`/clubBoc/prospect/${prospectId}/uploads/documents`, {
    responseType: "blob",
  });

export const unlockProspect = async (prospectId: number): Promise<void> =>
  await AuthClient.put(`/clubBoc/prospect/${prospectId}/unlock`);

/* Tutorials */
export const getAllTutorials = async (): Promise<TutorialUploadDTO[]> =>
  (await AuthClient.get(`/tutorial/all`)).data.entity;
export const createNewTutorial = async (tutorial: TutorialUploadDTO): Promise<TutorialUploadDTO> =>
  (await AuthClient.post(`/tutorial`, tutorial)).data.entity;

export const deleteTutorialById = async (tutorialId: number): Promise<any> =>
  (await AuthClient.delete(`/tutorial/${tutorialId}`)).data.entity;

/* Club User Settings */
export const getClubUserSettings = async (): Promise<ClubUserSettingsDTO> =>
  (await AuthClient.get(`/clubUserSettings`)).data.entity;

export const saveClubUserSettings = async (settings: ClubUserSettingsDTO): Promise<any> =>
  (await AuthClient.post(`/clubUserSettings`, settings)).data.entity;

/* Help Resources */
export const getHelpResources = async (): Promise<HelpResourceDTO[]> =>
  (await AuthClient.get("/help/resources")).data.entity;

export const uploadHelpResource = async (uploadDTO: FileUploadDTO): Promise<any> =>
  (await AuthClient.post("/help/resources", uploadDTO)).data.entity;

export const deleteHelpResource = async (helpResourceId: number): Promise<any> =>
  (await AuthClient.delete(`/help/resources/${helpResourceId}`)).data.entity;

export const getHelpDescription = async (): Promise<HelpDescription> =>
  (await AuthClient.get("/help/description")).data.entity;

export const updateHelpDescription = async (description: string): Promise<any> =>
  (await AuthClient.put("/help/description", { description: description })).data.entity;

/* Prospect Documents */
export const uploadProspectDocument = async (
  prospectId: number,
  uploadDTO: FileUploadDTO,
): Promise<ProspectUploadDTO> =>
  (await AuthClient.post(`/clubBoc/prospect/${prospectId}/documents`, uploadDTO)).data.entity;

export const deleteProspectDocument = async (prospectId: number, uploadId: number): Promise<any> =>
  (await AuthClient.delete(`/clubBoc/prospect/${prospectId}/documents/${uploadId}`)).data.entity;

export const downloadProspectBlankSwornDeclaration = async (prospectId: number): Promise<any> =>
  await AuthClient.get(`/clubBoc/prospect/${prospectId}/documents/sworn-declaration`, {
    responseType: "blob",
  });

export const uploadConsentForm = async (prospectId: number, uploadDTO: FileUploadDTO): Promise<ProspectUploadDTO> =>
  (await AuthClient.post(`/clubBoc/prospect/${prospectId}/uploads/consentForm`, uploadDTO)).data.entity;

export const getWaiverDescriptions = async ({
  year,
  languageId,
  countryId,
  waiverTypeId,
}: WaiverQuery): Promise<WaiverDescriptionDTO[]> => {
  const countryQuery: string = !!countryId ? `&countryId=${countryId}` : "";
  const languageQuery: string = !!languageId ? `&languageId=${languageId}` : "";
  const waiverTypeQuery: string = !!waiverTypeId ? `&waiverTypeId=${waiverTypeId}` : "";
  return (await AuthClient.get(`/waivers?year=${year}${countryQuery}${languageQuery}${waiverTypeQuery}`)).data.entity;
};

export const downloadWaiver = async (waiverId: number, waiverTypeId: number) =>
  await AuthClient.get(`/waivers/download/${waiverId}/type/${waiverTypeId}`, {
    responseType: "blob",
  });

export const getConsentFormDescription = async (consentFormId: string): Promise<ConsentFormDescriptionDTO> =>
  (await AuthClient.get(`/consentForm/${consentFormId}`)).data.entity;

export const getTppWaiverDescription = async (tppWaiverId: string): Promise<TppWaiverDescriptionDTO> =>
  (await AuthClient.get(`/tppWaiver/${tppWaiverId}`)).data.entity;

export const saveConsentFormDescription = async (consentForm: ConsentFormDescriptionDTO) =>
  (await AuthClient.post(`/consentForm`, consentForm)).data.entity;

export const saveTppWaiverDescription = async (tppWaiver: TppWaiverDescriptionDTO) =>
  (await AuthClient.post(`/tppWaiver`, tppWaiver)).data.entity;

export const getProspectConsentForm = async (prospectId: number): Promise<ProspectConsentFormDTO> =>
  (await AuthClient.get(`/clubBoc/prospect/${prospectId}/consent-form`)).data.entity;

export const getProspectConsentFormConfig = async (prospectId: number): Promise<ProspectConsentFormConfig> =>
  (await AuthClient.get(`/clubBoc/prospect/${prospectId}/consent-form/config`)).data.entity;

export const getProspectConsentFormGuardianSigners = async (
  prospectId: number,
): Promise<ConsentFormGuardianSignersDTO> =>
  (await AuthClient.get(`/clubBoc/prospect/${prospectId}/consent-form/signers`)).data.entity;

export const addProspectConsentFormGuardianSigner = async (
  prospectId: number,
  signer: ConsentFormSignerDTO,
): Promise<ConsentFormGuardianSignersDTO> =>
  (await AuthClient.post(`/clubBoc/prospect/${prospectId}/consent-form/signers`, signer)).data.entity;

export const updateProspectConsentFormSigners = async (
  prospectId: number,
  guardians: ConsentFormGuardianSignersDTO,
): Promise<ConsentFormGuardianSignersDTO> =>
  (await AuthClient.put(`/clubBoc/prospect/${prospectId}/consent-form/signers`, guardians)).data.entity;

export const downloadBlankProspectConsentForm = async (prospectId: number): Promise<any> =>
  await AuthClient.get(`/clubBoc/prospect/${prospectId}/consent-form/blank`, {
    responseType: "blob",
  });

export const downloadSingedProspectConsentForm = async (prospectId: number): Promise<any> =>
  await AuthClient.get(`/clubBoc/prospect/${prospectId}/consent-form/signed`, {
    responseType: "blob",
  });

export const checkDefaultConsentForm = async (year: number, consentFormId: number): Promise<boolean> =>
  (await AuthClient.get(`/consentForm/${year}/defaultCheck`, { params: { consentFormId: consentFormId } })).data.entity;

export const deleteConsentFormDescription = async (consentFormId: string): Promise<ConsentFormDescriptionDTO> =>
  (await AuthClient.delete(`/consentForm/${consentFormId}`)).data.entity;

export const deleteTppWaiverDescription = async (tppWaiverDescriptionId: string): Promise<ConsentFormDescriptionDTO> =>
  (await AuthClient.delete(`/tppWaiver/${tppWaiverDescriptionId}`)).data.entity;

/* Prospect Duplicate Detection */
export const retrievePotentialDuplicateAccounts = async (
  filters: ProspectGridFilters,
): Promise<DuplicateAccountDTO[]> => (await AuthClient.post(`/accounts/duplicates`, filters)).data.entity;

export const updateDuplicateAccounts = async (
  duplicateAccountManagementDTO: DuplicateAccountManagementDTO,
): Promise<any> =>
  (await AuthClient.post("/accounts/updateDuplicateAccounts", duplicateAccountManagementDTO)).data.entity;

/* Tasks */
export const getTask = async (taskId: string): Promise<TaskDTO> =>
  (await AuthClient.get(`/tasks/${taskId}`)).data.entity;

export const getAllTasks = async (): Promise<TaskDTO[]> => (await AuthClient.get(`/tasks/all`)).data.entity;

export const createUpdateTask = async (task: TaskDTO): Promise<TaskDTO> =>
  (await AuthClient.post(`/tasks/update`, task)).data.entity;

export const getActiveTasks = async (): Promise<TaskDTO[]> => (await AuthClient.get("/tasks/active")).data.entity;

export const assignTasksToProspects = async (assignmentDTO: TaskAssignmentDTO): Promise<any> =>
  (await AuthClient.post(`/tasks/assign`, assignmentDTO)).data.entity;

/* Settings */
export const getSettings = async (): Promise<SettingsDTO> => (await AuthClient.get(`/admin/settings`)).data.entity;

export const getSetting = async (key: string): Promise<string> =>
  (await AuthClient.get(`/systemSettings/${key}`)).data.entity;

export const saveSettings = async (settings: SettingsDTO): Promise<SettingsDTO> =>
  (await AuthClient.post(`/admin/settings`, settings)).data.entity;

export const getTrainerProfile = async (trainerId: string): Promise<TrainerProfileDTO> =>
  (await AuthClient.get(`/trainerGrid/${trainerId}/profile`)).data.entity;

/* Prospect Profile */
export const getProspectInformation = async (prospectId: number): Promise<ProspectDTO> =>
  (await AuthClient.get(`/clubBoc/prospect/${prospectId}`)).data.entity;

export const getProspectProfile = async (prospectId: number): Promise<ProspectProfileDTO> =>
  (await AuthClient.get(`/clubBoc/prospect/${prospectId}/profile`)).data.entity;

export const getProspectInvitations = async (prospectId: number): Promise<InviteDTO[]> =>
  (await AuthClient.get(`/clubBoc/prospect/${prospectId}/invitations`)).data.entity;

export const getProspectRequiredDocuments = async (prospectId: number): Promise<RequiredDocument[]> =>
  (await AuthClient.get(`/clubBoc/prospect/${prospectId}/documents/required`)).data.entity;

export const getProspectUploadedDocuments = async (prospectId: number): Promise<ProspectUploadDTO[]> =>
  (await AuthClient.get(`/clubBoc/prospect/${prospectId}/documents/uploaded`)).data.entity;

export const getProspectEbisSubmissionHistory = async (prospectId: number): Promise<EbisSubmission[]> =>
  (await AuthClient.get(`/clubBoc/prospect/${prospectId}/submission-history`)).data.entity;

export const saveProspectPhoneNumber = async (
  prospectId: number,
  phoneNumber: PhoneNumberDTO,
): Promise<PhoneNumberDTO> =>
  (await AuthClient.post(`/clubBoc/prospect/${prospectId}/phone-number`, phoneNumber)).data.entity;

export const saveProspectEmailAddress = async (
  prospectId: number,
  emailAddress: EmailAddressDTO,
): Promise<EmailAddressDTO> =>
  (await AuthClient.post(`/clubBoc/prospect/${prospectId}/email-address`, emailAddress)).data.entity;

export const associateProspectWithTrainer = async (prospectId: number, trainerId: number): Promise<any> =>
  (await AuthClient.post(`/clubBoc/prospect/${prospectId}/trainers/${trainerId}/associate`)).data.entity;

export const disassociateProspectWithTrainer = async (prospectId: number, trainerId: number): Promise<any> =>
  (await AuthClient.post(`/clubBoc/prospect/${prospectId}/trainers/${trainerId}/disassociate`)).data.entity;

export const saveProspectProfessionalTeam = async (
  prospectId: number,
  body: ProspectProfessionalTeamDTO,
): Promise<ProspectProfessionalTeamDTO> =>
  (await AuthClient.post(`/clubBoc/prospect/${prospectId}/profile/pro-team`, body)).data.entity;

export const saveProspectTravelDate = async (
  prospectId: number,
  body: ProspectTravelDateDTO,
): Promise<ProspectTravelDateDTO> =>
  (await AuthClient.post(`/clubBoc/prospect/${prospectId}/profile/travel-date`, body)).data.entity;

export const deleteProspectProfessionalTeam = async (prospectId: number, prospectProTeamId: number): Promise<void> =>
  (await AuthClient.delete(`/clubBoc/prospect/${prospectId}/profile/pro-team/${prospectProTeamId}`)).data.entity;

export const deleteProspectTravelDate = async (prospectId: number, travelDateId: number): Promise<void> =>
  (await AuthClient.delete(`/clubBoc/prospect/${prospectId}/profile/travel-date/${travelDateId}`)).data.entity;

/* Prospect Trainers */
export const createTrainer = async (trainer: TrainerDTO): Promise<TrainerDTO> =>
  (await AuthClient.post(`/trainers/create`, trainer)).data.entity;

export const searchTrainers = async (
  filters: TrainerGridFilters,
  page: number,
  size: number,
): Promise<TrainerGridPage> =>
  (await AuthClient.post(`/trainerGrid/search?page=${page}&size=${size}`, filters)).data.entity;

export const getTrainerMatches = async (trainer: TrainerDTO): Promise<TrainerDTO[]> =>
  (await AuthClient.post(`/trainers/matches`, trainer)).data.entity;

export const getTrainers = async (textSearch: string): Promise<TrainerDTO[]> =>
  (await AuthClient.post(`/trainers/search`, { textSearch })).data.entity;

export const saveProspect = async (prospectId: number, profile: SaveProspectProfileDTO): Promise<ProspectProfileDTO> =>
  (await AuthClient.post(`/clubBoc/prospect/${prospectId}/profile`, profile)).data.entity;

// export const saveAndSubmitProspect = async (
//   prospectId: number,
//   prospectProfileDTO: ProspectProfileDTO,
// ): Promise<ProspectProfileDTO> =>
//   (await AuthClient.post(`/clubBoc/prospect/${prospectId}/profile/saveAndSubmit`, prospectProfileDTO)).data.entity;

export const submitProspectProfile = async (prospectId: number): Promise<ProspectProfileDTO> =>
  (await AuthClient.post(`/clubBoc/prospect/${prospectId}/profile/submit`)).data.entity;

export const completeProspectProfile = async (prospectId: number): Promise<any> =>
  (await AuthClient.post(`/clubBoc/prospect/${prospectId}/profile/complete`)).data.entity;

export const associateProspectWithOrg = async (prospectId: number): Promise<void> =>
  await AuthClient.post(`/clubBoc/prospect/${prospectId}/associate`);

/* Reports */
export const downloadEbisProcessingErrorReport = async (): Promise<any> =>
  await AuthClient.get(`/reports/ebisProcessingErrorReport`, {
    responseType: "blob",
  });

export const downloadWaitingOnGuardianSignaturesReport = async (): Promise<any> =>
  await AuthClient.get(`/reports/waitingOnGuardianSignaturesReport`, {
    responseType: "blob",
  });

export const downloadDocumentsIncompleteReport = async (): Promise<any> =>
  await AuthClient.get(`/reports/documentsIncompleteReport`, {
    responseType: "blob",
  });
